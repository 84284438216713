import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import axios from 'axios'
import VueAxios from 'vue-axios'

import "bootstrap";  // 引入js
import "@/scss/main.scss";  // 引入scss
import 'sweetalert2/dist/sweetalert2.min.css';



library.add(faPenToSquare)

createApp(App).use(VueSweetalert2).use(store).use(router).use(VueAxios, axios).component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
