import { createStore } from 'vuex'

export default createStore({
  state: {
    project: {}
  },
  getters: {
  },
  mutations: {
    save(state, data) {
      state.project = data;
    }
  },
  actions: {
  },
  modules: {
  }
})
