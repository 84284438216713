<template>
  <div class="row align-items-md-stretch">
    <div class="col-md-3">
      <div class="h-100 p-4 text-white bg-dark rounded-3">
        <div id="q-status-finish" style="display: none;">
          <h1>結算</h1>
          <h2 class="text-muted">Result</h2>
        </div>
        <div id="q-status-start">
          <h1>物品</h1>
          <h2 class="text-muted">Object</h2>

          <p id="q-number" class="q-number text-muted"><span id="q-index" class="text-light">{{
            PROBLEMS_INDEX
          }}</span>/<span id="q-total" class="q-total">{{ PROBLEMS_COUNT }}</span></p>
          <img id="q-img" class="d-block w-100 rounded-3" src="../assets/icon_obj.png" alt="" @load="imgOnLoad"
            ref="qImg">
          <canvas id="q-canvas" style="display: none;" width="224" height="224"></canvas>
          <h1 id="q-name" class="q-title mt-3 mb-4"></h1>
          <div class="d-grid gap-2">
            <button id="btn-start" type="button" class="btn btn-primary btn-lg" style="display: none;"
              @click="clickStart">辨識</button>
            <button id="btn-next" type="button" class="btn btn-secondary btn-lg" @click="clickNext"
              :disabled="asyncLocker < TEAM_COUNT">開始</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="row align-items-md-stretch" v-if="store.state.project.model">
        <div class="col-md-6 mt-sm-4" v-for="(ml, index) in store.state.project.model" :key="ml.index"
          :class="{ 'mt-md-4': index > 1, 'mt-md-0': index <= 1 }">
          <div class="p-4 bg-light border rounded-3">
            <h4> {{ ml.name }} <span class="team-icon-crown" :id="'team-crown-' + (index + 1)"><img
                  src="../assets/icon_crown.png" width="32"></span><span :id="'team-stauts-' + (index + 1)"
                style="display: none;">
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                <span class="visually-hidden">Loading...</span></span></h4>
            <p class="counter-title">得分</p>
            <div :id="'result-score-' + (index + 1)" class="counter-box border-bottom"><span
                class="counter">0</span><span class="counter-plus"></span>分</div>
            <div :id="'result-object-' + (index + 1)" class="result-box border-bottom">
              <p class="text-muted">準備就緒</p>
            </div>

            <div :id="'result-progress-' + (index + 1)"></div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
import * as tmImage from '@teachablemachine/image';
import { ref, onMounted } from 'vue';
import store from "@/store";
import Swal from "sweetalert2";
import router from '@/router';

const $ = require('jquery');

window.$ = $;

var OBJECT_PROBLEMS = [];

const PROBLEMS_COUNT = ref(0);
const PROBLEMS_INDEX = ref(0);
var PROGRESS_TITLE = [];
var PROGRESS_COLOR = ['success', 'warning', 'info', 'danger'];

var TEAM_COUNT = 0;
var TEAM_MODEL = [];
var TEAM_SCORE = [];
var TEAM_MODEL_TF = [];
const TEAM_MODEL_LOADED = ref(0);

const asyncLocker = ref(99);


const inital = async () => {
  var dataModel = store.state.project.model;
  
  TEAM_COUNT = dataModel.length;

  dataModel.forEach(m => {
    TEAM_MODEL.push(m.url);
    TEAM_SCORE.push(0);
  });

  var dataLabel = store.state.project.dataset;

  dataLabel.forEach(l => {
    PROGRESS_TITLE.push(l.label);
    l.image.forEach(img => {
      OBJECT_PROBLEMS.push({ 'label': PROGRESS_TITLE[l.index], 'image': img });
    });
  })
  PROBLEMS_COUNT.value = OBJECT_PROBLEMS.length;

  shuffle(OBJECT_PROBLEMS);

  console.log(OBJECT_PROBLEMS)

  TEAM_MODEL_LOADED.value = 0;
  /*for (let i = 1; i <= TEAM_COUNT; i++) {
    await modelLoaded(i);
  }*/
};

const updateRun = () => {
  PROBLEMS_INDEX.value++
  if (PROBLEMS_INDEX.value <= PROBLEMS_COUNT.value) {
    var img = $("#q-img");
    img.attr("src", OBJECT_PROBLEMS[PROBLEMS_INDEX.value - 1].image);
    img.attr("alt", OBJECT_PROBLEMS[PROBLEMS_INDEX.value - 1].label);
  }

  console.log(PROBLEMS_INDEX.value, PROBLEMS_COUNT.value)

  var crown = -1;
  var crown_value = 0;
  for (let i = 0; i < TEAM_COUNT; i++) {
    if (TEAM_SCORE[i] > crown_value) {
      crown = i;
      crown_value = TEAM_SCORE[i];
    }
  }

  $(".team-icon-crown").hide();

  if (crown != -1) {
    $("#team-crown-" + (crown + 1)).show();
  }

  for (let i = 1; i <= TEAM_COUNT; i++) {

    $("#result-score-" + i + " .counter-plus").fadeOut({
      done: function () {
        $("#result-score-" + i).prop('Counter', $("#result-score-" + i + " span.counter").text()).animate({
          Counter: TEAM_SCORE[i - 1]
        }, {
          duration: 1000,
          easing: 'swing',
          step: function (now) {
            $(this).children('span.counter').text((now).toFixed(1));
          }
        });
      }
    });


    if (PROBLEMS_INDEX.value <= PROBLEMS_COUNT.value) {

      var predObject = $("<p>").addClass("text-muted").text('等待辨識');
      $("#result-object-" + i).html("");
      $("#result-object-" + i).append(predObject);

      $("#result-progress-" + i).html("");
      for (let j = 0; j < PROGRESS_TITLE.length; j++) {

        var title = $("<h3>").addClass("progress-title").text(PROGRESS_TITLE[j] + " - 0%");
        var progress = $("<div>").addClass("progress")
        var bar = $("<div>").addClass("progress-bar progress-bar-striped active").width("0%");
        bar.addClass("bg-" + PROGRESS_COLOR[j]);
        bar.appendTo(progress);


        $("#result-progress-" + i).append(title, progress);
      }
    } else {
      $("#result-object-" + i).hide();
      $("#result-progress-" + i).hide();

      $("#q-status-finish").show();
      $("#q-status-start").hide();
    }
  }

};

/*const modelLoaded = async (team) => {
  const modelURL = TEAM_MODEL[team - 1] + "model.json";
  const metadataURL = TEAM_MODEL[team - 1] + "metadata.json";
  TEAM_MODEL_TF[team - 1] = await tmImage.load(modelURL, metadataURL);
  TEAM_MODEL_LOADED.value++;
}*/

const imgOnLoad = () => {
  var canvas = $("#q-canvas")[0];
  var ctx = canvas.getContext("2d");
  ctx.drawImage($("#q-img")[0], 0, 0);
  $("#btn-start").attr('disabled', false);
}

const predict = async (team) => {

  $("#team-stauts-" + team).show();
  const modelURL = TEAM_MODEL[team - 1] + "model.json";
  const metadataURL = TEAM_MODEL[team - 1] + "metadata.json";

  var model = TEAM_MODEL_TF[team - 1];

  if (model == null) {
    model = await tmImage.load(modelURL, metadataURL);
    TEAM_MODEL_TF[team - 1] = model;
  }

  const prediction = await model.predict($("#q-canvas")[0]);

  var predName = "未知";
  var predValue = 0;
  $("#result-progress-" + team).html("");

  for (let i = 0; i < PROGRESS_TITLE.length; i++) {
    const tempValue = Math.round(prediction[i].probability.toFixed(2) * 100);

    const tempName = prediction[i].className;

    if (tempValue > predValue) {
      predName = tempName;
      predValue = tempValue;
    }

    var title = $("<h3>").addClass("progress-title").text(tempName + " - " + tempValue + "%");
    var progress = $("<div>").addClass("progress")
    var bar = $("<div>").addClass("progress-bar progress-bar-striped active").width(tempValue + "%");
    bar.addClass("bg-" + PROGRESS_COLOR[i]);
    bar.appendTo(progress);

    $("#result-progress-" + team).append(title, progress);
  }

  var predObject = $("<p>").text(predName);

  var score_plus = 0;
  if (predName == OBJECT_PROBLEMS[PROBLEMS_INDEX.value - 1].label) {
    score_plus = (10 * (predValue / 100.0));
    predObject.addClass("text-success");
  } else {
    predObject.addClass("text-danger");
  }
  $("#result-object-" + team).html("");
  $("#result-object-" + team).append(predObject);
  if (score_plus > 0) {
    TEAM_SCORE[team - 1] += score_plus;
    $("#result-score-" + team + " .counter-plus").text("+" + ((score_plus).toFixed(1)));
    $("#result-score-" + team + " .counter-plus").fadeIn();
  }

  $("#team-stauts-" + team).hide();

  asyncLocker.value++;
}

const clickStart = () => {
  asyncLocker.value = 0;
  for (let i = 1; i <= TEAM_COUNT; i++) {
    predict(i);
  }

  $("#btn-start").hide();
  $("#btn-next").show();

}

const clickNext = () => {

  $("#btn-next").hide();
  $("#btn-next").text("下一題")

  updateRun();

  if (PROBLEMS_INDEX.value == PROBLEMS_COUNT.value) {
    $("#btn-next").text("結算");
  }

  $("#btn-start").show();
}

onMounted(() => {
  if (store.state.project.dataset && store.state.project.model) {

    inital();
    /*
        Swal.fire(
          '提示',
          '辨識第一組照片時，須自網路下載AI模型檔，會耗費較長時間，請耐心等待',
          'warning'
        )*/

    $("#btn-next").show();
    $("#btn-start").hide();
  } else {
    Swal.fire({
      title: '尚未設置任何比賽資訊',
      text: '請前往設置頁面新增比賽資訊',
      showCancelButton: false,
      confirmButtonText: '立即前往',
      allowOutsideClick: false,
      allowEscapeKey: false,
      icon: 'info'
    }).then((result) => {
      if (result.isConfirmed) {
        router.push('/setting');
      }
    });
  }
});

/*
const toChinese = (val) => {
  let chin = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'];

  if (val <= 10) {
    return chin[val - 1];
  } else {
    return 'undefined';
  }
}*/

const shuffle = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}
</script>
