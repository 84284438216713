<template>
  <div class="container py-4">

    <header class="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
      <p class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
        <img src="./assets/logo.png" width="48">
        <span class="fs-4">最強分類器爭霸賽</span>
      </p>

      <ul class="nav nav-pills">
        <li class="nav-item"> <router-link class="nav-link" to="/setting">設置</router-link></li>
        <li class="nav-item"><router-link class="nav-link" to="/">比賽</router-link></li>
      </ul>
    </header>

    <router-view />

    <footer class="pt-3 mt-4 text-muted border-top">
      Version {{ version }} (Build {{ build }}) &copy; <a class="footer-link" href="https://dsa.im"
        target="_blank">DSAIM Team</a>. 2023
    </footer>
  </div>
</template>

<style lang="scss">

</style>

<script setup>
import { onMounted } from 'vue';
import router from '@/router';
import axios from 'axios'
import Swal from "sweetalert2";
import adapter from 'axios/lib/adapters/http'


// eslint-disable-next-line
let version = APPLICATION_VERSION
// eslint-disable-next-line
let build = BUILD_VERSION

onMounted(() => {

  router.push('/setting');


  axios.get("https://classifier.dsa.im/check.php", { adapter: adapter })
    .then((response) => {
      console.log()
      if (response.data.build > build) {
        Swal.fire({
          title: '發現新版本',
          text: '新版本：' + response.data.version + '(build ' + response.data.build + ')',
          showCancelButton: true,
          confirmButtonText: '前往查看',
          cancelButtonText: '下次再說',
          icon: 'info'
        }).then((result) => {
          if (result.isConfirmed) {
            require('electron').shell.openExternal('https://dsa.im/2023/01/06/ai-classified-tool/');
          }
        });
      }
    });
});
</script>